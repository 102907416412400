import React from 'react';

import Layout from '../components/Layout';
import pic5 from '../assets/images/pic05.jpg';
import banner from '../assets/images/banner.jpg';
import { Link } from 'gatsby';

const TeachingPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Teaching</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>Teaching Philosophy</h3>
          <p>
            <span className="image right">
              <img src={pic5} alt="" />
            </span>
            As a teaching artist, I believe that education is the key to the
            success of the next generation, and ultimately our societies. I feel
            that sharing my love of music and life through teaching is one of
            the most powerful tools I have to help make the world an even more
            beautiful place. I know all children have the ability to learn
            anything with the right support systems in place. As Dr. Shinichi
            Suzuki says, “Every Child Can!” I agree; every child has the
            potential to learn to do anything. My teaching is rooted in this
            philosophy of Dr. Suzuki’s, and I apply it to all of my students
            whether they are curious beginners, driven young violinists, or
            adventurous adults looking to try out a new hobby. Talent is not
            something you are born with, but something anyone can develop with
            lots of hard work and support. Learning the violin is no walk in the
            park, it’s a marathon! But with dedication, daily practice, lessons,
            listening and love, anyone can learn how to play the violin.{' '}
          </p>
          <p>
            Over the last decade, I have had the honor of teaching hundreds of
            students. With that experience I have come to realize that every
            child is unique and requires a customized approach to teaching.
            Although there is a method to my madness, what works for some kids
            simply doesn’t work for others! In my studio, each student is given
            a safe space to have fun, explore, make mistakes, learn how to adapt
            and ultimately achieve their goals. I have a holistic approach to
            teaching and incorporate my practice of yoga and neuroscience based
            practice methods to reach each student. As a trained Suzuki method
            instructor, I use the method for all of my beginner students, and
            expand my approach for more advanced students with the teachings and
            philosophies of luminary violin pedagogues Joseph Gingold, Paul
            Rolland, Mimi Zweig, Charles Krigbaum and Edmund Sprunger.{' '}
          </p>
          <p>
            As a teacher, my goal is to awaken a lifelong love of music inside
            every student and help them realize their full potential as
            individuals, regardless of whether or not they become musicians.
            Learning how to play music is one of the most beneficial things a
            child and adolescent can do during their formative years. Through
            lessons and daily practice they develop a strong work ethic, gain
            confidence, become creative, learn how to work with others, and even
            improve their math and reading skills - all while making music and
            having fun. Tell me, does it get any better than that?{' '}
          </p>

          <div className="ResponsiveGrid">
            <div>
              <h4>Education & Certifications </h4>
              <ul>
                <li>Carnegie Mellon University - Bachelor of Fine Arts</li>
                <li>McGill University - Master of Music</li>
                <li>Lynn University - Professional Performer Certificate</li>
                <li>Suzuki Teacher Training: Violin Book 1 and 2</li>
                <li>Indiana University String Academy Pedagogy Workshop</li>
                <li>
                  The Brain-Body Science of Learning, Growth Mindset and Talent
                  Development Workshop
                </li>
              </ul>
            </div>
            <div>
              <h4>Services Offered</h4>
              <ul>
                <li>Beginner and Advanced Violin Lessons</li>
                <li>Young Musician Mentorship</li>
                <li>College Audition Preparation</li>
                <li>NYSSMA and All-State Audition Preparation</li>
                <li>Parent Education and Support</li>
              </ul>
              <Link
                style={{ marginBottom: '2em' }}
                to="/Scheduling"
                className="button primary"
              >
                Schedule Now
              </Link>
            </div>
          </div>

          <span className="image fit">
            <img src={banner} alt="" />
          </span>
        </div>
      </section>
    </article>
  </Layout>
);

export default TeachingPage;
